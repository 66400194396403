import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import MainTitle from '../components/MainTitle'
import RegisterForm from '../components/RegisterForm'

const Cadastro = ({ data }) => (
  <Layout>
    <SEO title="Fale Conosco" />

    <MainTitle
        content={ `<h1>É muito bom ter <br /><em>você</em> aqui</h1><p>Gostaríamos de fazer algumas perguntas para manter um cadastro
        atualizado. Todas estas informações confidenciais.</p>`} />

    <RegisterForm />

  </Layout>
)

export default Cadastro
